import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle } from '../components'

import ImageGallery from 'react-image-gallery';

const Awards = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [awards, setAwards] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "awards"]|order(orderRank){
      name,
      "img": img.asset->url
    }`
      )
      .then((data) => setAwards(data))
      .catch(console.error);
  }, []);

  if (!awards) {
    return (
      <h1>Loading...</h1>
    )
  }

  var images = [];
  awards.map((award) => {
    images.push({
      original: award.img,
      thumbnail: award.img,
      description: award.name
    })

    return images
  })

  return (
    <section id='awards' className='container px-6'>
      <SectionTitle title='Awards' />

      <p className='text-white text-lg pb-20 max-w-[800px] mx-auto text-center'>We have been incredibly fortunate, and through our dedicated efforts, we have received several prestigious awards, including the HBA BarberShop of the Year 2024 in the UK and the British Master Barbers BarberShop of the Year 2023 in the UK.</p>

      {/* <div className="awards hidden md:flex flex-wrap gap-[60px] justify-center items-center">
        {awards.map((award, index) => {
          var even = (index % 2 === 0)

          return (
            <div className={`award fancy-border w-[300px] h-[400px] ${even ? 'rotate-[-3deg]' : 'rotate-3'} hover:scale-[1.05] transition-all`} key={award + index}>
              <img src={urlFor(award.img)} alt={award.name} className='w-full h-full object-cover' />
            </div>
          )
        })}
      </div> */}

      <div className='h-max'>
        <ImageGallery
          items={images}
          showPlayButton={false}
        />;
      </div>
    </section>
  )
}

export default Awards