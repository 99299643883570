import React from 'react'
import { SectionTitle, GalleryComponent } from '../components'

const FidgetsGallery = () => {
  return (
    <section className='px-6 container'>
      <SectionTitle title='Explore Our Fidgets Area' />
      <GalleryComponent query='fidgetsGallery' />
    </section>
  )
}

export default FidgetsGallery