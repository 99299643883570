import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle, TextImage } from '../components'

const About = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [aboutSection, setAboutSection] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]|order(orderRank){
      text,
      img,
      btn
    }`
      )
      .then((data) => setAboutSection(data))
      .catch(console.error);
  }, []);

  if (aboutSection) {
    var about = aboutSection[0]
  } else {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <section id='about' className='container px-6'>
      <SectionTitle title='About Us' />
      <TextImage
        img={urlFor(about.img)}
        imgAlt='about'
        text={about.text}
        reverse={false}
        btnText={about.btn.text}
        btnLink={about.btn.link}
      />
    </section>
  )
}

export default About