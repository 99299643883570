import React from 'react'
import { SectionTitle, GalleryComponent } from '../components'

const Gallery = () => {
  return (
    <section id='gallery' className='px-6 container'>
      <SectionTitle title='Explore Our Salon' />
      <GalleryComponent query={'gallery'} />
    </section>
  )
}

export default Gallery