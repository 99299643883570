import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle } from '../components'

const Contact = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [contactInfo, setContactInfo] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contact"]|order(orderRank){
      info,
      img,
      btn,
      mapLink
    }`
      )
      .then((data) => setContactInfo(data))
      .catch(console.error);
  }, []);

  if (!contactInfo) {
    return (
      <h1>Loading...</h1>
    )
  } else {
    var contact = contactInfo[0]
  }

  return (
    <section id='contact' className='container px-6 pb-5'>
      <SectionTitle title='Contact' />

      <div className={`flex flex-col sm:flex-row gap-[60px] items-center`}>
        <div className="content flex-1 flex flex-col items-center sm:items-start">
          <div className="p-text mb-10 gap-5 flex flex-col">
            {contact.info.map((item, index) => (
              <p className='text-white text-lg' key={item + index}>{item.split('\n').map((line) => (
                <span key={line}>{line}<br /></span>
              ))}</p>
            ))}
          </div>

          <iframe
            src={contact.mapLink}
            className='border-none mb-10 w-[100%] max-w-[450px] h-[300px]'
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            />

          <a href={contact.btn.link} target='_blank' className='cursor-pointer text-white sm:m-0 mx-auto py-[10px] px-[16px] text-xl capitalize bg-[#22363f] button-hover'>{contact.btn.text}</a>


        </div>

        <div className="image hidden md:flex flex-1">
          <img src={urlFor(contact.img)} alt='contact'
            className={``}
          />
        </div>
      </div>
    </section>
  )
}

export default Contact