import React from 'react'

const SectionTitle = ({ title }) => {
    return (
        <h2 className='w-[100%] flex text-center justify-center py-20 script text-white text-6xl glow-text'>
            {title}
        </h2>
    )
}

export default SectionTitle